<template>
  <div v-show="show" class="channel-section">
    <!-- <div class="section-header">
      <el-select
        class="channel-selector"
        size="small"
        v-model="value"
        placeholder="— — 频道选择 — —">
        <el-option
          :value="''"
          :label="''">
        </el-option>
      </el-select>
    </div> -->
    <div class="video-container">
      <video controls id="sportChannel"></video>
    </div>
  </div>
</template>

<script>
import flvjs from 'flv.js'

export default {
  props: ['show', 'url'],
  data () {
    return {
      flvPlayer: null,
      // loading: true,
      // showPlayerBtn: false
    }
  },
  mounted () {
    this.attachVideo()
  },
  destroyed () {
    if (this.flvPlayer) {
      this.flvPlayer.pause()
      this.flvPlayer.unload()
      this.flvPlayer.detachMediaElement()
      this.flvPlayer = null
    }
  },
  methods: {
    attachVideo () {
      let stream = this.url
      if (flvjs.isSupported() && stream !== '') {
        let sportChannel = document.getElementById('sportChannel')
        this.flvPlayer = flvjs.createPlayer({
          type: 'flv',
          url: stream,
          isLive: true,
          cors: true,
        })
        this.flvPlayer.attachMediaElement(sportChannel)
        this.flvPlayer.load()
        // this.loading = false
        // this.showPlayerBtn = true
        // this.flvPlayer.play()
      }
    }
  },
  watch: {
    url () {
      if (!this.flvPlayer) {
        this.attachVideo()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.channel-section {
  width: 100%;
  height: 446px;
  margin-bottom: 20px;
  .section-header {
    text-align: center;
    padding: 10px;
    border-radius: 15px 15px 0px 0px;
    background: #269633;
    // .channel-selector {}
  }
  .video-container {
    position: relative;
    width: 100%;
    height: inherit;
    background: rgb(241, 241, 241);
    &:hover {
      .video-player-button {
        animation: sparking 2s linear infinite;
        @keyframes sparking {
          0% {opacity: 0.7;}
          50% {opacity: 0.4;}
          100% {opacity: 0.7;}
        }
      }
    }
    .video-loading {
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 68px;
      color: white;
      opacity: 0.7;
    }
    .video-player-button {
      position: absolute;
      display: inline-block;
      width: 80px;
      height: 80px;
      border: 6px solid white;
      text-align: center;
      line-height: 70px;
      border-radius: 50%;
      padding-left: 4px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 68px;
      color: white;
      opacity: 0.7;
    }
    #sportChannel {
      width: 100%;
      height: 446px;
    }
  }
}
</style>