<template>
  <div 
    class="multi-checkbox-dialog"
    :class="`${sport}-style`">
    <div 
      class="select-button"
      :class="[{'disable-button': leagues.length == 0}, {'revert-button': showCheckbox}]"
      @click="handleShowCheckbox()">
      <div class="text-container">{{ showText }}</div>
      <i class="el-icon-arrow-down"></i>
    </div>
    <div 
      v-show="showCheckbox"
      class="checkbox-container">
      <div class="container-header">
        <button class="check-all-button" @click="handleCheckAll">全选</button>
        <button class="cancel-all-button" @click="handleCancelAll">全部取消</button>
        <button class="close-button" @click="handleShowCheckbox()">
          <i class="el-icon-error"></i>
        </button>
      </div>
      <div class="container-body">
        <el-checkbox-group 
          class="checkbox-group"
          v-model="checkedLeagues" 
          @change="handleCheckedLeagues">
          <el-checkbox 
            class="checkbox-column"
            v-for="league in leagues" 
            :label="league" 
            :key="league">
            {{league}}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sport: {
      type: String,
    },
    showText: {
      type: String,
      default: ''
    },
    leagues: {
      type: Array,
      default: []
    },
    close: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      arrow_icon: 'el-icon-arrow-down',
      checkStatus: 1, // 0: empty, 1: all, 2: some
      checkedLeagues: [],
      showCheckbox: false,
      close_status: 0
    }
  },
  created () {
    this.close_status = this.close
  },
  methods: {
    handleCheckAll () {
      this.checkedLeagues = this.leagues
      this.checkStatus = 1
      this.emitChange()
    },
    handleCancelAll () {
      this.checkedLeagues = []
      this.checkStatus = 0
      this.emitChange()
    },
    handleCheckedLeagues (value) {
      this.checkedLeagues = value
      this.checkStatus = value.length === this.leagues.length ? 1 :
                         value.length == 0 ? 0 : 2
      this.emitChange()
    },
    handleShowCheckbox () {
      if (this.leagues.length == 0) {
        this.showCheckbox = false
        this.close_status = 0
        return
      }
      this.showCheckbox = !this.showCheckbox
      this.close_status = this.showCheckbox ? 1 : 0
      this.$emit('changeStatus', this.close_status)
    },
    emitChange () {
      this.$emit('changeCheckBox', {
        state: this.checkStatus,
        value: this.checkedLeagues
      })
    },
  },
  watch: {
    leagues: {
      handler(cur, prev) {
        if (cur !== prev && cur.length > 0) {
          this.checkedLeagues = cur
        }
      },
      deep: true
    },
    close (cur) {
      if (cur == 0 && this.showCheckbox == true) {
        this.handleShowCheckbox()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/sportStyles';
.multi-checkbox-dialog {
  .checkbox-container {
    .container-body {
      .checkbox-group {
        .el-checkbox__label {
          width: 100%;
          font: normal normal normal 14px/30px Arial;
          color: #828282;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-word;
          vertical-align: middle;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner, 
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: #FFFFFF;
          border-color: #D7D7D7;
        }
        .el-checkbox__inner::after {
          width: 4px;
          height: 11px;
          left: 3px;
          top: -2px;
          // border: 2px solid #409EFF;
          transition: none;
        }
        .el-checkbox__inner {
          border: 1px solid #D7D7D7;
          background: #D7D7D7;
          border-radius: 0;
        }
        .el-checkbox__input.is-checked+.el-checkbox__label {font-weight: bold;}
      }
    }
  }
  &.football-style {
    .checkbox-container {
      .container-body {
        .checkbox-group {
          // .el-checkbox__input.is-checked+.el-checkbox__label {color: $football-269633;}
          .el-checkbox__input.is-checked .el-checkbox__inner::after {
            border: 2px solid $football-269633;
            border-width: 2px;
            border-left: 0;
            border-top: 0;
          }
        }
      }
    }
  }
  &.baseketball-style {
    .checkbox-container {
      .container-body {
        .checkbox-group {
          // .el-checkbox__input.is-checked+.el-checkbox__label {color: $baseketball-D65F33;}
          .el-checkbox__input.is-checked .el-checkbox__inner::after {
            border: 2px solid $baseketball-D65F33;
            border-width: 2px;
            border-left: 0;
            border-top: 0;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/sportStyles';
.multi-checkbox-dialog {
  .select-button {
    display: flex;
    width: 100%;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    color: #828282;
    font: normal normal normal 14px/21px Poppins;
    line-height: 30px;
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
    padding: 0 8px 0 15px;
    cursor: pointer;
    &.disable-button {
      color: #b8b8b8;
      background: #e4e4e4;
      border: 1px solid #dfdfdf;
      cursor: not-allowed;
    }
    &.revert-button {
      & i {
        transition: transform .3s;
        transform: rotateZ(-180deg);
      }
    }
    .text-container {
      max-width: calc(100% - 25px);
      overflow: hidden;
      white-space: nowrap;
      word-break: break-word;
      text-overflow: ellipsis;
      margin-right: 5px;
    }
    & i {
      width: 20px;
      color: #C0C4CC;
    }
  }
  .checkbox-container {
    position: absolute;
    width: 717px;
    height: fit-content;
    background: #FFFFFF;
    left: 50%;
    transform: translate(-50%, 3px);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
    z-index: 99;
    .container-header {
      width: 100%;
      display: grid;
      grid-template-columns: auto auto 1fr;
      grid-column-gap: 16px;
      justify-content: start;
      align-items: center;
      padding: 8px 16px 6px;
      & button {
        color: #FFFFFF;
        background: transparent;
        border: none;
        &:focus, &:active {outline: none;}
      }
      .check-all-button, .cancel-all-button {
        width: 80px;
        height: 26px;
        text-align: center;
        font: normal normal normal 14px/25px Poppins;
        border: 1px solid #FFF;
        border-radius: 5px;
      }
      .close-button {
        width: 26px;
        height: 26px;
        justify-self: end;
        font: normal normal normal 24px/26px Poppins;
      }
    }
    .container-body {
      .empty-content {
        width: 100%;
        min-height: 100px;
        color: #828282;
        font: normal normal normal 14px/100px Arial;
        line-height: 100px;
        text-align: center;
      }
      .checkbox-group {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 33.333%);
        // grid-column-gap: 1px;
        // grid-row-gap: 1px;
        border: 1px solid #D7D7D7;
        border-bottom: none;
        border-top: none;
        background: #FFF;
        .checkbox-column {
          width: 100%;
          height: 30px;
          padding: 0 12px;
          text-align: left;
          border-right: 1px solid #D7D7D7;
          border-bottom: 1px solid #D7D7D7;
          background: #FFFFFF;
          &:nth-child(3n) {
            border-right: none;
          }
        }
      }
    }
  }
  &.football-style {
    .checkbox-container {
      .container-header {
        background: $football-66BB6A;
        border: 1px solid $football-C5DDC7;
      }
    }
  }
  &.baseketball-style {
    .checkbox-container {
      .container-header {
        background: $baseketball-F79737;
        border: 1px solid $baseketball-F4A557;
      }
    }
  }
}
</style>