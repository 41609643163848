<template>
  <div 
    class="table-body-section"
    :class="`${sport}-table`">
    <div v-if="isLoading" class="empty-status">
      <i class="el-icon-loading"></i>
      <span>加载中...</span>
    </div>
    <div v-else>
      <div 
        v-if="results.length <= 0" 
        class="empty-status">
        {{showStatus}}
      </div>
      <div v-else>
        <el-collapse v-model="activePanels" @change="handleActivePanel">
          <el-collapse-item
            class="sport-detail-panel"
            v-for="(res, idx) in results"
            :key="res + idx"
            :name="'panel_' + idx">
            <template slot="title">
              {{res.league}}
            </template>
            <div class="panel-table-container">
              <Table
                :sport="sport"
                :data="res.plays" />
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import Table from './table'

export default {
  components: {
    Table
  },
  props: {
    sport: {
      type: String
    },
    filtered: {
      type: Number,
      default: 0
    },
    results: {
      type: Array,
      default: []
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    unfold_all: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      status: { 0:'暂无数据', 1:'筛选后无符合数据' },
      activePanels: [],
    }
  },
  computed: {
    showStatus () {
      return this.status[this.filtered]
    }
  },
  methods: {
    handleAvtAllPanels () {
      let count = this.results.length, arr = []
      for(let i = 0; i < count; i++) {
        arr.push(`panel_${i}`)
      }
      this.activePanels = arr
    },
    handleActivePanel (val) {
      let arr = this.activePanels
      let panelExist = arr.filter(item => {
        return item === val
      })
      if (panelExist) {
        this.arrayRemove(arr, val)
      } else {
        arr.push(val)
      }
      this.$emit("handleUnfoldPanels", false)
      this.activePanels = arr
    },
    arrayRemove (arr, value) {
      return arr.filter(item => { 
        return item != value
      })
    },
  },
  watch: {
    results: {
      handler (cur, prev) {
        if ( cur !== prev && cur.length > 0 ) {
          this.handleAvtAllPanels()
        }
      },
      deep: true
    },
    unfold_all (cur) {
      if (cur === true) {
        this.handleAvtAllPanels()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/sportStyles';
.table-body-section {
  .empty-status {
    font: normal normal normal 14px/21px Poppins;
    color: #828282;
    height: 280px;
    line-height: 280px;
    border: 1px solid #D7D7D7;
    border-top: 0;
    & i {margin-right: 5px;}
  }
  .sport-detail-panel {
    .el-collapse-item__header {
      height: 40px;
      color: #FFF!important;
      font: normal normal 600 14px/21px Poppins;
      padding: 10px 13px;
      border: 1px solid #D7D7D7;
      border-bottom: none;
      .el-collapse-item__arrow {
        font-size: 18px;
        font-weight: bolder;
      }
    }
    .el-collapse-item__wrap {
      border-bottom: none;
      .el-collapse-item__content {
        padding-bottom: 5px;
      }
    }
    .panel-table-container {
      min-width: 760px;
      overflow-x: scroll;
      &::-webkit-scrollbar {display: none;}
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }
  &.football-table {
    .sport-detail-panel {
      .el-collapse-item__header {
        background: $football-19601F;
      }
    }
  }
  &.baseketball-table {
    .sport-detail-panel {
      .el-collapse-item__header {
        background: $baseketball-533E29;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .table-body-section {
    .empty-status {
      font: normal normal normal 14px/21px Poppins;
      height: 150px;
      line-height: 150px;
      border: 1px solid #D7D7D7;
    }
    .sport-detail-panel {
      .el-collapse-item__header {
        height: 36px;
        color: #FFF;
        font: normal normal 600 14px/36px Poppins;
        padding: 0 12px;
        .el-collapse-item__arrow {
          font-size: 16px;
        }
      }
      .el-collapse-item__wrap {
        border-bottom: none;
        overflow: scroll;
        &::-webkit-scrollbar {display: none;}
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        .el-collapse-item__content {
          padding-bottom: 5px;
        }
      }
      .panel-table-container {
        min-width: 760px;
        overflow-x: scroll;
        &::-webkit-scrollbar {display: none;}
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .table-body-section {
    .empty-status {
      font: normal normal normal 14px/21px Poppins;
      height: 150px;
      line-height: 150px;
      border: 1px solid #D7D7D7;
    }
    .sport-detail-panel {
      .el-collapse-item__header {
        height: 36px;
        color: #FFF;
        font: normal normal 600 14px/36px Poppins;
        padding: 0 12px;
        .el-collapse-item__arrow {
          font-size: 16px;
        }
      }
      .el-collapse-item__wrap {
        border-bottom: none;
        overflow: scroll;
        &::-webkit-scrollbar {display: none;}
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        .el-collapse-item__content {
          padding-bottom: 5px;
        }
      }
      .panel-table-container {
        min-width: 760px;
        overflow-x: scroll;
        &::-webkit-scrollbar {display: none;}
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .table-body-section {
    .sport-detail-panel {
      .el-collapse-item__wrap {
        overflow: scroll;
        &::-webkit-scrollbar {display: none;}
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */ 
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .table-body-section {
    .sport-detail-panel {
      .el-collapse-item__wrap {
        overflow: scroll;
        &::-webkit-scrollbar {display: none;}
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    }
  }
}
@media (min-width: 1200px) {
  .table-body-section {
    .empty-status {
      height: 280px;
      line-height: 280px;
    }
    .sport-detail-panel {
      .el-collapse-item__header {
        height: 40px;
        padding: 10px 13px;
      }
    }
  }
}
</style>