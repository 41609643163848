<template>
  <div 
    class="detail-league-table"
    :class="`${sport}-table`">
    <table v-if="data">
      <thead>
        <tr>
          <th colspan="5">全场</th>
          <th colspan="3">半场</th>
        </tr>
      </thead>
      <tbody
        v-for="(tbody, tidx) in data"
        :key="tbody.league + '_' + tidx">
        <tr>
          <th colspan="2"></th>
          <th
            class="bet-header"
            v-for="(head, idx) in table_header[sport]"
            :key="`${idx}-${head}`">
            {{head}}
          </th>
        </tr>
        <tr>
          <td class="play-time-col" v-html="tbody.play_at_html"></td>
          <td class="text-left team-name">
            <div>{{tbody.home_team}}</div>
            <div>{{tbody.away_team}}</div>
            <div v-if="tbody.team_txt">{{tbody.team_txt}}</div>
          </td>
          <td colspan="3" class="betting-col">
            <div
              class="bet-group"
              v-for="(group, gidx) in tbody.data"
              :key="`${tidx}_${tbody.league}_${gidx}`">
              <div
                class="bet-group-row"
                :class="checkBetRowShow(group, 'full', row)"
                v-for="(row, ridx) in bet_row"
                :key="`${row}_${tidx}_${ridx}_${gidx}`">
                <div
                  class="bet-group-col"
                  :class="getBetColClass(tbody.data, 'full', col)"
                  v-for="(col, cidx) in bet_col[sport]"
                  :key="`${col}_${tidx}_${ridx}_${cidx}_${gidx}`">
                  <span 
                    v-show="group[row]['full'][col].odds_txt"
                    class="red mark-txt">
                    {{group[row]['full'][col].odds_txt}}
                  </span>
                  <div
                    v-show="group[row]['full'][col].odds"
                    class="bet-button">
                    {{group[row]['full'][col].odds}}
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td colspan="3" class="betting-col">
            <div
              class="bet-group"
              v-for="(group, gidx) in tbody.data"
              :key="`${tidx}_${tbody.league}_${gidx}`">
              <div
                class="bet-group-row"
                :class="checkBetRowShow(group, 'half', row)"
                v-for="(row, ridx) in bet_row"
                :key="`${row}_${tidx}_${ridx}_${gidx}`">
                <div
                  class="bet-group-col"
                  :class="getBetColClass(tbody.data, 'half', col)"
                  v-for="(col, cidx) in bet_col[sport]"
                  :key="`${col}_${tidx}_${ridx}_${cidx}_${gidx}`">
                  <span
                    v-show="group[row]['half'][col].odds_txt"
                    class="red mark-txt">
                    {{group[row]['half'][col].odds_txt}}
                  </span>
                  <div 
                    v-show="group[row]['half'][col].odds"
                    class="bet-button">
                    {{group[row]['half'][col].odds}}
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr 
          v-if="tbody.hasOwnProperty('more_bet_area') && 
                tbody.more_bet_area.length !== 0">
          <td colspan="8" class="more-bet-col">
            <div class="more-bet-container">
              <div class="more-bet-colums">
                <div
                  class="more-bet-block"
                  v-for="(area, areaidx) in tbody.more_bet_area[0]"
                  :key="`${tidx}-0-${areaidx}-area`">
                  <div class="more-bet-header">{{area.bet_name}}</div>
                  <div class="more-bet-content">
                    <div class="more-bet-box"
                      v-for="(box, bidx) in area.bet_cols"
                      :key="`${tidx}-1-${areaidx}-${bidx}`">
                      <div>
                        <span>{{checkMoreBetData(box.left)}}</span>
                        <span>{{checkMoreBetData(box.right)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="more-bet-colums">
                <div
                  class="more-bet-block"
                  v-for="(area, areaidx) in tbody.more_bet_area[1]"
                  :key="`${tidx}-${areaidx}-area`">
                  <div class="more-bet-header">{{area.bet_name}}</div>
                  <div class="more-bet-content">
                    <div class="more-bet-box"
                      v-for="(box, bidx) in area.bet_cols"
                      :key="`${tidx}-${areaidx}-${bidx}`">
                      <div>
                        <span>{{checkMoreBetData(box.left)}}</span>
                        <span>{{checkMoreBetData(box.right)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Table from '../../Admin/Market/Benefit/Table.vue'
import { byteLength, byteSlice } from '@/utils/string'

export default {
  components: { Table },
  props: ['sport', 'data'],
  data () {
    return {
      table_header: {
        football: ['独赢盘', '让球', '大小', '独赢盘', '让球', '大小'],
        baseketball: ['胜负盘', '让球', '大小', '胜负盘', '让球', '大小']
      },
      bet_row: ['home_team_odds', 'away_team_odds', 'extra_odds'],
      bet_col: {
        football: ['doin', 'luncho', 'bigsmall'],
        baseketball: ['sanfoo', 'luncho', 'bigsmall']}
    }
  },
  computed: {
    getBetColClass () {
      return function (data, court, col) {
        let result = data.every(item => {
          let boo = true
          for(let key in item) {
            if(item[key][court][col].odds_txt && item[key][court][col].odds_txt !== '') {
              boo = false
              break
            }
          }
          return boo
        })
        return result ? 'align-center' : ''
      }
    },
    checkBetRowShow () {
      return (data, court, row) => {
        let boo = true, obj = data[row][court]
        for(let key in obj) {
          if (obj[key].odds && obj[key].odds !== '') {
            boo = false
            break
          }
        }
        return boo ? 'hide-row' : ''
      }
    }
  },
  methods: {
    getRowSpan (idx) {
      let countData = this.data[idx].data.length, all = 0
      for(let i = 0; i < countData; i++) {
        all += Object.keys(this.data[idx].data[i]).length
      }
      return all
    },
    checkMoreBetData (data) {
      let str = ''
      if (data && data !== '') {
        let arr = data.split(' ')
        for(let i = 0; i < arr.length; i++) {
          if (parseFloat(arr[i]) !== NaN) {
            if (byteLength(str) >= 10) {
              str = byteSlice(str, 10)
            }
            str += ' ' + arr[i]
          } else {
            let reg = /^[a-zA-Z]+$/
            if (arr[i].match(reg)) {
              str += arr[i]
            } else {
              str += arr[i].length > 6 ? arr[i].slice(0, 6) + '...' : arr[i]
            }
          }
        }
      }
      return str
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/sportStyles';
.play-at-time {color: $football-269633;}
</style>
<style lang="scss" scoped>
@import '@/styles/sportStyles';
.detail-league-table{
  width: 100%;
  cursor: default;
  & table {
    width: 100%;
    border-collapse: collapse;
    & th, & td {
      border: 1px solid #D7D7D7;
      padding: 3px;
      text-align: center;
      &.text-left {text-align: left;}
      .red {color: #EB5757;}
    }
    & thead {
      th {
        color: #FFF;
        font: normal normal medium 13px/20px Poppins;
      }
    }
    & tbody {
      th {
        color: #5D5D5D;
        font: normal normal normal 12px/18px Poppins;
        &.bet-header{width: 95px;}
      }
      td {
        color: #5D5D5D;
        font: normal normal normal 12px/18px Poppins;
        // padding: 3px 7px 3px 3px;
        padding: 10px 13px;
        vertical-align: top;
        // &.play-time-col {padding: 20px 13px 0;}
        &.team-name {
          width: 140px;
          max-width: 140px;
          padding: 0;
          & > div {
            width: 100%;
            height: 26px;
            line-height: 26px;
            text-align: left;
            padding-left: 13px;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-word;
            overflow: hidden;
            // border-bottom: 1px solid #D7D7D7;
          }
        }
        &.betting-col {
          padding: 0;
          vertical-align: top;
          font: normal normal normal 13px/22px Helvetica;
          .bet-group {
            margin-bottom: 14px;
            &:last-child{margin-bottom: 0;}
            .bet-group-row {
              display: grid;
              height: 26px;
              line-height: 26px;
              align-items: center;
              grid-template-columns: 33.3% 33.3% 33.3%;
              &.hide-row {display: none;}
              .bet-group-col {
                text-align: right;
                padding: 0 5px;
                &.align-center {text-align: center;}
                .mark-txt {
                  display: inline-block;
                  min-width: 20px;
                  max-width: 40px;
                  margin-right: 3px;
                  text-align: center;
                }
                .bet-button {
                  display: inline-block;
                  width: 46px;
                  font: normal normal normal 13px/18px Helvetica;
                  text-align: center;
                  color: #5D5D5D;
                  border: 1px solid #D7D7D7;
                  border-radius: 5px;
                  background: #FFF;
                }
              }
            }
          }
        }
        &.more-bet-col {
          .more-bet-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2px;
            padding: 0 1px;
            .more-bet-colums {
              .more-bet-block {
                margin-bottom: 2px;
                background: #F4F4F4;
                .more-bet-header {
                  height: 26px;
                  font: normal normal normal 12px/26px Poppins;
                  color: #5D5D5D;
                  text-align: left;
                  padding: 0 15px;
                }
                .more-bet-content {
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  padding: 0 15px 5px;
                  grid-gap: 10px;
                  // padding: 4px 0;
                  .more-bet-box {
                    display: flex;
                    height: 24px;
                    justify-content: space-between;
                    align-items: center;
                    background: #FFFFFF;
                    border: 1px solid #D7D7D7;
                    border-radius: 5px;
                    & > div {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding: 0 8px;
                      & span {
                        &:first-child {font: normal normal normal 13px/22px Helvetica;}
                        &:last-child {font: normal normal 300 13px/22px Helvetica;}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.football-table {
    & table {
      & thead {
        th {
          background: $football-93AD95;
        }
      }
      & tbody {
        th {
          background: $football-E2F4E4;
        }
      }
    }
  }
  &.baseketball-table {
    & table {
      & thead {
        th {
          background: $baseketball-AF9790;
        }
      }
      & tbody {
        th {
          background: $baseketball-F4EEEC;
        }
      }
    }
  }
}
</style>