<template>
  <div 
    class="table-header-section"
    :class="`${sport}-table`">
    <div class="section-header">
      <el-select
        class="table-selector"
        size="small"
        v-model="type"
        :placeholder="``"
        @focus="handleCloseCheckbox">
        <el-option
          v-for="(opt, key) in typeOptions"
          :key="opt + key"
          :value="opt.value"
          :label="opt.key">
        </el-option>
      </el-select>
    </div>
    <div class="table-container">
      <div class="table-filter">
        <div>
          <!-- <button class="result-button">赛果</button> -->
          <button 
            class="show-all-button"
            @click="handleShowAll">显示全部</button>
          <div>
            <label>赔率：</label>
            <el-select 
              class="table-selector bet-selector"
              size="small"
              v-model="locale_odds"
              @change="handleLocale"
              @focus="handleCloseCheckbox">
              <el-option
                v-for="(opt, key) in betOptions"
                :key="opt + key"
                :label="opt.key"
                :value="opt.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <div class="league-selector">
            <MultiCheckbox
              ref="multicheckbox"
              @changeCheckBox="handleLeague"
              @changeStatus="changeStatus"
              :close="checkbox_status"
              :sport="sport"
              :showText="league_show"
              :leagues="leagues" />
          </div>
        </div>
        <div>
          <div>
            <el-select 
              class="table-selector sort-selector"
              size="small"
              v-model="sort"
              @change="handleSort"
              @focus="handleCloseCheckbox">
              <el-option
                v-for="(opt, key) in sortOptions"
                :key="opt + key"
                :label="opt.key"
                :value="opt.value">
              </el-option>
            </el-select>
            <button
              class="revert-button"
              @click="handleDataReverse">
              <!-- <i class="el-icon-sort"></i> -->
              <b-icon :icon="sort_icon" font-scale="1.5" shift-v="-1.5"></b-icon>
            </button>
          </div>
          <button
            class="refresh-button"
            @click="handleReload">
            <i class="el-icon-refresh"></i>
          </button>
        </div>
      </div>
      <div class="table-date-selector">
        <button 
          ref="prevButton"
          class="prev-button" 
          @click="handleDatePrev">
          <i class="el-icon-caret-left"></i>
        </button>
        <div class="date-list" ref="dateListBar" @scroll="handleShowDateButton">
          <ul ref="dateList">
            <li
              :class="{'date-sel-act':item.value === belong_date}"
              v-for="(item, key) in getAWeek"
              :key="item + key"
              @click="handlePlayAt(item.value)"
            >{{item.key}}</li>
          </ul>
        </div>
        <button 
          ref="nextButton"
          class="next-button" 
          @click="handleDateNext">
          <i class="el-icon-caret-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getDateRange, getZhWeekday, dateFormat } from '@/utils/time'
import MultiCheckbox from '@/components/multiCheckbox'

export default {
  components: {
    MultiCheckbox
  },
  props: ['sport', 'tab_info', 'checkbox_status'],
  data () {
    return {
      league_show: '所有联赛',
      type: 'dilcds',
      locale_odds: 'malay',
      category: 'early',
      league: '',
      belong_date: '',
      sort: 'normal',
      results: [],
      reverse: false,
      sort_icon: 'sort-down'
    }
  },
  created () {
    this.init()
  },
  mounted () {
    // document.addEventListener('click', this.outClick, true)
    // window.addEventListener('scroll', this.handleShowDateButton, true)
  },
  destroyed () {
    // document.removeEventListener('click', this.outClick, true)
    // window.removeEventListener('scroll', this.handleShowDateButton, true)
  },
  computed: {
    // outClick (e) {
    //   let multicheckbox = this.$refs.multicheckbox.$el
    //   if (!multicheckbox.contains(e.target)) {
    //     this.handleCloseCheckbox()
    //   }
    // },
    today () {
      return dateFormat('yyyy-MM-dd', new Date())
    },
    getAWeek () {
      let week = getDateRange(7)
      return week.map(date => {
        let weekday = getZhWeekday(date)
        let dateArr = date.split('-')
        return {
          key: date === this.today ? `今日` : `周${weekday} ${dateArr[1]}月${dateArr[2]}日`,
          value: date
        }
      })
    },
    typeOptions () {
      return this.tab_info.type
    },
    betOptions () {
      return this.tab_info.betting
    },
    leagues () {
      this.league_show = this.tab_info.league.length == 0 ? '无' : '所有联赛'
      return this.tab_info.league
    },
    sortOptions () {
      return this.tab_info.sort
    }
  },
  methods: {
    init () {
      this.resetQuery()
      this.emitTableQuery({
        type: this.type,
        belong_date: this.belong_date,
        category: this.category,
        filtered: 0
      })
      this.$nextTick(() => {
        this.handleShowDateButton()
      })
    },
    resetQuery () {
      let firstTypeOpt = this.typeOptions.find((item, idx)=> idx === 0)
      this.type = firstTypeOpt.value
      this.sort = 'normal'
      this.locale_odds = 'malay'
      this.results = [],
      this.reverse = false,
      this.sort_icon = 'sort-down'
      this.belong_date = this.today
      this.category = 'today'
      // this.category = 'early'
      // this.belong_date = "2021-10-20"

    },
    handleShowAll () {
      this.$emit('handleUnfoldPanels', true)
    },
    handleCategory (val) {
      if (str === 'today') {
        this.emitTableQuery({
          category: val,
          belong_date: ''
        })
      }
    },
    handleLocale (val) {
      this.emitTableQuery({
        locale_odds: val,
      })
    },
    handlePlayAt (date) {
      if (date) {
        let today = this.today
        this.belong_date = date
        this.emitTableQuery({
          category: today === date ? 'today' : 'early',
          belong_date: date
        })
      }
    },
    handleLeague (obj) {
      // obj: {state: 0(empty) || 1(all) || 2(some), value: array}
      let league_str = ''
      switch (obj.state) {
        case 0:
          league_str = '*'
          this.league_show = '无'
          break;
        case 1:
          league_str = ''
          this.league_show = '所有联赛'
          break;
        case 2:
          league_str = obj.value.join(',')
          this.league_show = league_str
          break;
      }
      this.emitTableQuery({
        league: league_str
      })
    },
    handleSort (val) {
      this.emitTableQuery({
        sort: val
      })
    },
    handleDataReverse () {
      this.$emit('handleTableReverse')
      this.reverse = !this.reverse
      this.sort_icon = this.reverse ? 'sort-up' : 'sort-down'
    },
    handleShowDateButton () {
      let parentWidth = this.$refs.dateListBar.clientWidth
      let contentWidth = this.$refs.dateList.clientWidth
      if (contentWidth <= parentWidth) {
        this.$refs.prevButton.classList.add('disable-button')
        this.$refs.nextButton.classList.add('disable-button')
      } else {
        if (this.$refs.dateListBar.scrollLeft <= 0) {
          this.$refs.prevButton.classList.add('disable-button')
        } else {
          this.$refs.prevButton.classList.remove('disable-button')
        }
        if (this.$refs.dateListBar.scrollLeft > contentWidth - parentWidth) {
          this.$refs.nextButton.classList.add('disable-button')
        } else {
          this.$refs.nextButton.classList.remove('disable-button')
        }
      }
    },
    handleDatePrev () {
      let diff = this.$refs.dateList.clientWidth - this.$refs.dateListBar.clientWidth
      let curPosition = this.$refs.dateListBar.scrollLeft
      if (curPosition > 0) {
        this.$nextTick(()=>{
          this.$refs.dateListBar.scrollLeft = this.$refs.dateListBar.scrollLeft - 100
          if (this.$refs.dateListBar.scrollLeft < diff) {
            this.$refs.nextButton.classList.remove('disable-button')
          }
          if (this.$refs.dateListBar.scrollLeft <= 0) {
            this.$refs.prevButton.classList.add('disable-button')
          }
        })
      }
    },
    handleDateNext () {
      let diff = this.$refs.dateList.clientWidth - this.$refs.dateListBar.clientWidth
      let curPosition = this.$refs.dateListBar.scrollLeft
      if (curPosition < diff) {
        this.$nextTick(()=>{
          this.$refs.dateListBar.scrollLeft = this.$refs.dateListBar.scrollLeft + 100
          if (this.$refs.dateListBar.scrollLeft >= diff) {
            this.$refs.nextButton.classList.add('disable-button')
          }
          if (this.$refs.dateListBar.scrollLeft > 0) {
            this.$refs.prevButton.classList.remove('disable-button')
          }
        })
      }
    },
    emitTableQuery (obj) {
      this.$emit('handleDataQuery', obj)
    },
    handleReload () {
      this.$emit('handleReload')
    },
    changeStatus (int) {
      this.$emit('handleMultiCheckboxStatus', parseInt(int))
    },
    handleCloseCheckbox () {
      this.$emit('handleMultiCheckboxStatus', 0)
    }
  },
  watch: {
    sport (cur, prev) {
      if(cur !== prev) {
        this.init()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/sportStyles';
.table-header-section {
  .el-input__inner {
    color: #828282;
    font: normal normal normal 14px/30px Poppins;
    border-radius: 0;
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
  }
  .table-selector {
    .el-input--small .el-input__inner{
      height: 30px;
      line-height: 30px;
      &:focus {border-color: #D7D7D7;}
    }
    .el-input--small .el-input__icon {line-height: 30px;}
  }
  .bet-selector{width: 100px;}
  .sort-selector {width: 120px;}
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #D7D7D7;
  }
}
@media (max-width: 575.98px) {
  .table-header-section {
    .bet-selector{width: 90px;}
    .sort-selector {width: 120px;}
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .table-header-section {
    .bet-selector{width: 90px;}
    .sort-selector {width: 120px;}
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .table-header-section {
    .bet-selector{width: 90px;}
    .sort-selector {width: 120px;}
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .table-header-section {
    .bet-selector{width: 90px;}
    .sort-selector {width: 120px;}
  }
}
@media (min-width: 1200px) {
  .table-header-section {
    .bet-selector{width: 100px;}
    .sort-selector {width: 120px;}
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/sportStyles';
.table-header-section {
  .section-header {
    text-align: left;
  }
  .table-container {
    width: 100%;
    margin-top: 14px;
    & button {
      width: 80px;
      height: 30px;
      color: #FFFFFF;
      font: normal normal normal 14px/21px Poppins;
      border: none;
      border-radius: 5px;
      &:focus, &:active {outline: none;}
    }
    .table-filter {
      position: relative;
      display: grid;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid #D7D7D7;
      margin-bottom: 5px;
      grid-template-columns: 1.2fr minmax(150px, 0.8fr) 1fr;
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        font: normal normal normal 14px/21px Poppins;
        color: #FFFFFF;
        padding: 10px 15px;
        &:nth-child(1) {
          // flex-basis: 45%;
          justify-content: space-between;
          // .show-all-button {margin: 0 8px;}
        }
        &:nth-child(2) {
          // flex-basis: 30%;
          justify-self: stretch;
          border-left: 1px solid #D7D7D7;
          border-right: 1px solid #D7D7D7;
          color: #FFFFFF;
          .league-selector {
            width: 90%;
          }
        }
        &:nth-child(3) {
          // flex-basis: 35%;
          justify-content: space-between;
          & > div {
            display: flex;
            align-items: center;
            .revert-button {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              color: #FFFFFF;
              background: transparent;
              & i {
                font-size: 18px;
                line-height: 28px;
              }
            }
          }
          .refresh-button {
            width: 30px;
            margin-left: 20px;
            & i {
              font-size: 24px;
              line-height: 30px;
            }
          }
        }
      }
    }
    .table-date-selector {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #FFFFFF;
      border: 1px solid #D7D7D7;
      padding: 0 13px;
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 90px;
        height: 30px;
        top: 0;
        right: 0;
        z-index: 1;
      }
      .prev-button, .next-button {
        position: relative;
        width: 20px;
        height: 20px;
        font: normal normal normal 14px/20px Poppins;
        background: #FFFFFF;
        border-radius: 50%;
        z-index: 5;
        &.disable-button{
          opacity: 0.2;
          cursor: default;
        }
      }
      .date-list {
        width: calc(100% - 40px);
        padding: 0 10px;
        overflow-x: auto;
        &::-webkit-scrollbar {display: none;}
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        scroll-behavior: smooth;
        & ul {
          display: flex;
          min-width: max-content;
          align-items: center;
          & li {
            margin-right: 12px;
            font: normal normal normal 14px/17px Helvetica;
            cursor: pointer;
            padding: 10px 0;
            &.date-sel-act{color: #FFE200;}
          }
        }
      }
    }
  }
  &.football-table {
    .table-container {
      & button {
        background: $football-0B2C0F;
      }
      .table-filter {
        background: $football-19601F;
      }
      .table-date-selector {
        background: $football-44A047;
        &::after {
          background: transparent linear-gradient(90deg, $football-44A04700 0%, $football-44A047 100%) 0% 0% no-repeat padding-box;
        }
        .prev-button, .next-button {
          color: $football-44A047;
          & i {color: $football-44A047;}
        }
      }
    }
  }
  &.baseketball-table {
    .table-container {
      & button {
        background: $baseketball-100902;
      }
      .table-filter {
        background: $baseketball-533E29;
      }
      .table-date-selector {
        background: $baseketball-9F6A43;
        &::after {
          background: transparent linear-gradient(90deg, $baseketball-9F6A4300 0%, $baseketball-9F6A43 100%) 0% 0% no-repeat padding-box;
        }
        .prev-button, .next-button {
          color: $baseketball-9F6A43;
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .table-header-section {
    .table-container {
      margin-top: 10px;
      & button {
        width: 76px;
        height: 28px;
        font: normal normal normal 12px/28px Poppins;
      }
      .table-filter {
        grid-template-columns: 1fr;
        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
          font: normal normal normal 14px/21px Poppins;
          color: #FFFFFF;
          padding: 10px 15px;
          &:nth-child(1) {
            justify-content: space-between;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            display: none;
          }
        }
      }
      .table-date-selector {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #FFFFFF;
        border: 1px solid #D7D7D7;
        padding: 0 13px;
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 90px;
          height: 30px;
          top: 0;
          right: 0;
          z-index: 1;
        }
        .prev-button, .next-button {
          position: relative;
          width: 20px;
          height: 20px;
          font: normal normal normal 14px/20px Poppins;
          background: #FFFFFF;
          border-radius: 50%;
          z-index: 5;
          &.disable-button{
            opacity: 0.2;
            cursor: default;
          }
        }
        .date-list {
          width: calc(100% - 40px);
          padding: 0 10px;
          overflow-x: auto;
          &::-webkit-scrollbar {display: none;}
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
          & ul {
            display: flex;
            min-width: max-content;
            align-items: center;
            & li {
              padding: 10px 0;
              margin-right: 12px;
              font: normal normal normal 14px/17px Helvetica;
              cursor: pointer;
              &.date-sel-act{color: #FFE200;}
            }
          }
        }
      }
    }
    &.football-table {
      .table-container {
        & button {
          background: $football-0B2C0F;
        }
        .table-filter {
          background: $football-19601F;
        }
        .table-date-selector {
          background: $football-44A047;
          &::after {
            background: transparent linear-gradient(90deg, $football-44A04700 0%, $football-44A047 100%) 0% 0% no-repeat padding-box;
          }
          .prev-button, .next-button {
            color: $football-44A047;
            & i {color: $football-44A047;}
          }
        }
      }
    }
    &.baseketball-table {
      .table-container {
        & button {
          background: $baseketball-100902;
        }
        .table-filter {
          background: $baseketball-533E29;
        }
        .table-date-selector {
          background: $baseketball-9F6A43;
          &::after {
            background: transparent linear-gradient(90deg, $baseketball-9F6A4300 0%, $baseketball-9F6A43 100%) 0% 0% no-repeat padding-box;
          }
          .prev-button, .next-button {
            color: $baseketball-9F6A43;
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .table-header-section {
    .table-container {
      margin-top: 10px;
      & button {
        width: 76px;
        height: 28px;
        font: normal normal normal 12px/28px Poppins;
      }
      .table-filter {
        grid-template-columns: 1fr;
        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
          font: normal normal normal 14px/21px Poppins;
          color: #FFFFFF;
          padding: 10px 15px;
          &:nth-child(1) {
            justify-content: space-between;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            display: none;
          }
        }
      }
      .table-date-selector {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #FFFFFF;
        border: 1px solid #D7D7D7;
        padding: 0 13px;
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 90px;
          height: 30px;
          top: 0;
          right: 0;
          z-index: 1;
        }
        .prev-button, .next-button {
          position: relative;
          width: 20px;
          height: 20px;
          font: normal normal normal 14px/20px Poppins;
          background: #FFFFFF;
          border-radius: 50%;
          z-index: 5;
          &.disable-button{
            opacity: 0.2;
            cursor: default;
          }
        }
        .date-list {
          width: calc(100% - 40px);
          padding: 0 10px;
          overflow-x: auto;
          &::-webkit-scrollbar {display: none;}
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
          & ul {
            display: flex;
            min-width: max-content;
            align-items: center;
            & li {
              padding: 10px 0;
              margin-right: 12px;
              font: normal normal normal 14px/17px Helvetica;
              cursor: pointer;
              &.date-sel-act{color: #FFE200;}
            }
          }
        }
      }
    }
    &.football-table {
      .table-container {
        & button {
          background: $football-0B2C0F;
        }
        .table-filter {
          background: $football-19601F;
        }
        .table-date-selector {
          background: $football-44A047;
          &::after {
            background: transparent linear-gradient(90deg, $football-44A04700 0%, $football-44A047 100%) 0% 0% no-repeat padding-box;
          }
          .prev-button, .next-button {
            color: $football-44A047;
            & i {color: $football-44A047;}
          }
        }
      }
    }
    &.baseketball-table {
      .table-container {
        & button {
          background: $baseketball-100902;
        }
        .table-filter {
          background: $baseketball-533E29;
        }
        .table-date-selector {
          background: $baseketball-9F6A43;
          &::after {
            background: transparent linear-gradient(90deg, $baseketball-9F6A4300 0%, $baseketball-9F6A43 100%) 0% 0% no-repeat padding-box;
          }
          .prev-button, .next-button {
            color: $baseketball-9F6A43;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .table-header-section {
    .table-container {
      & button {
        width: 70px;
        height: 30px;
      }
      .table-filter {
        margin-bottom: 5px;
        grid-template-columns: 1.2fr minmax(150px, 0.8fr) 1fr;
        & > div {
          font: normal normal normal 14px/21px Poppins;
          padding: 10px 15px;
          &:nth-child(2) {
            .league-selector {
              width: 90%;
            }
          }
          &:nth-child(3) {
            & > div {
              .revert-button {
                width: 30px;
                height: 30px;
                & i {
                  font-size: 18px;
                  line-height: 28px;
                }
              }
            }
            .refresh-button {
              width: 30px;
              margin-left: 20px;
              & i {
                font-size: 24px;
                line-height: 30px;
              }
            }
          }
        }
      }
      .table-date-selector {
        padding: 0 13px;
        .prev-button, .next-button {
          width: 20px;
          height: 20px;
          font: normal normal normal 14px/20px Poppins;
        }
        .date-list {
          width: calc(100% - 40px);
          padding: 0 10px;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .table-header-section {
    .table-container {
      margin-top: 14px;
      & button {
        width: 70px;
        height: 30px;
        font: normal normal normal 14px/30px Poppins;
      }
      .table-filter {
        grid-template-columns: 1.2fr minmax(130px, 0.8fr) 1fr;
        & > div {
          padding: 10px 15px;
          &:nth-child(3) {
            .refresh-button {
              margin-left: 12px;
            }
          }
        }
      }
      .table-date-selector {
        
      }
    }
  }
}
@media (min-width: 1200px) {
  .table-header-section {
    .table-container {
      & button {
        width: 70px;
        height: 30px;
      }
      .table-filter {
        margin-bottom: 5px;
        grid-template-columns: 1.2fr minmax(150px, 0.8fr) 1fr;
        & > div {
          font: normal normal normal 14px/21px Poppins;
          padding: 10px 15px;
          &:nth-child(2) {
            .league-selector {
              width: 90%;
            }
          }
          &:nth-child(3) {
            & > div {
              .revert-button {
                width: 30px;
                height: 30px;
                & i {
                  font-size: 18px;
                  line-height: 28px;
                }
              }
            }
            .refresh-button {
              width: 30px;
              margin-left: 20px;
              & i {
                font-size: 24px;
                line-height: 30px;
              }
            }
          }
        }
      }
      .table-date-selector {
        padding: 0 13px;
        .prev-button, .next-button {
          width: 20px;
          height: 20px;
          font: normal normal normal 14px/20px Poppins;
        }
        .date-list {
          width: calc(100% - 40px);
          padding: 0 10px;
        }
      }
    }
  }
}
</style>