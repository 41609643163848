<template>
  <div 
    class="sport-detail-recommend-list"
    :class="`${sport}-style`">
    <div class="recommend-header">
      <img src="@/assets/sport-crown.png" />
      <h3>{{title[sport]}}推荐赛事</h3>
    </div>
    <div class="recommend-container">
      <button 
        v-if="lists.length > 0"
        ref="prevButton"
        class="prev-button" 
        @click="handleListPrev">
        <i class="el-icon-caret-left"></i>
      </button>
      <div 
        v-if="lists.length == 0"
        class="empty-content">
        <img :src="emptyImage" />
        <h4>现在没有推荐的赛事</h4>
      </div>
      <div 
        v-else 
        ref="listBar"
        class="recommend-list"
        @scroll="handleShowListButton">
        <div 
          ref="listContainer" 
          class="recommend-list-container">
          <div
            class="recommend-box"
            :class="item.class"
            v-for="(item, idx) in sortData"
            :key="`recommend_${idx}`">
            <div class="league-section">{{item.league}}</div>
            <div class="info-section">
              <div class="team-name">{{item.team1}}</div>
              <div class="info-detail" v-html="item.info_html"></div>
              <div class="team-name">{{item.team2}}</div>
            </div>
            <div class="bet-section">
              <div class="bet-box home-bet" v-html="item.bet_html.team1">
                <!-- <span>{{checkData(item.mini_odds.team1.txt)}}</span>
                <span>{{checkData(item.mini_odds.team1.odds)}}</span> -->
              </div>
              <div class="bet-text">{{item.mini_odds.txt_inner}}</div>
              <div class="bet-box away-bet" v-html="item.bet_html.team2">
                <!-- <span>{{checkData(item.mini_odds.team2.txt)}}</span>
                <span>{{checkData(item.mini_odds.team2.odds)}}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <button 
        v-if="lists.length > 0"
        ref="nextButton"
        class="next-button" 
        @click="handleListNext">
        <i class="el-icon-caret-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['sport', 'lists'],
  data () {
    return {
      title: {
        football: '足球',
        baseketball: '篮球'
      },
      hasHandleButton: false
    }
  },
  created () {
  },
  watch: {
    sport(cur, prev) {
      if (cur !== prev) {
        this.hasHandleButton = false
        this.$nextTick(()=> {
          this.handleShowListButton()
        })
      }
    },
    lists(cur) {
      if (cur.length > 0 && !this.hasHandleButton){
        this.$nextTick(()=> {
          this.handleShowListButton()
        })
      }
    }
  },
  computed: {
    emptyImage () {
      return require(`@/assets/${this.sport}-recommend-empty.png`)
    },
    sortData () {
      // let date_rex = /\d{2}[/-]\d{2}/g
      // let time_res = /\d{2}[:]\d{2}/g
      return this.lists.map(list => {
        let newItem = list, status = list.info, info = [], info_html = ''
        for(let line = 0; line < 3; line++) {
          switch (line) {
            case 0: {
              let priority = ['score', 'prepare', 'date']
              let obj = this.findInfoString(status, priority)
              info.push(obj)
              break;
            }
            case 1: {
                let priority = this.checkKey(info, 'date') ? ['time'] : ['section', 'vs', 'time']
                let obj = this.findInfoString(status, priority)
                info.push(obj)
                break;
              }
            case 2: {
              if ( info.length > 0 && !this.checkKey(info, 'section') ) {
                let priority = this.checkKey(info, 'time') ? ['court', 'vs'] : ['court', 'live', 'time', 'vs']
                let obj = this.findInfoString(status, priority)
                info.push(obj)
              }
              break;
            }
          }
        }
        info_html = this.mixInfoHtml(info.length > 1 ? info : status)
        newItem.class = this.checkKey(info, 'score') ? 'act-style' : ''
        newItem.info_html = info_html
        newItem.bet_html = this.createBetHtml(list)
        return newItem
      })
    },
  },
  methods: {
    checkData (str) {
      return !str || str == '' ? '－' : str
    },
    findInfoString (status, priority) {
      for(let i = 0; i < priority.length; i++) {
        let item = priority[i], res = ''
        switch (item) {
          case 'score':
            res = status.find(el => {return el.indexOf('-') > -1})
            if(res)
              return {score: res}
            else
              break
          case 'prepare':
            res = status.find(el => {return el.indexOf('即将滚球') > -1 && el.indexOf('滚球') > -1})
            if(res)
              return {prepare: res}
            else
              break
          case 'date':
            res = status.find(el => {return el.indexOf('/') > -1})
            if(res)
              return {date: res}
            else
              break
          case 'live':
            res = status.find(el => {return el == '滚球'})
            if(res)
              return {live: res}
            else
              break
          case 'section':
            res = status.find(el => {return (el.indexOf('第') > -1 && el.indexOf('节') > -1) || el.indexOf('加时') > -1 })
            if(res)
              return {section: res.slice(0, 3).replace(' ', '')}
            else
              break
          case 'vs':
            return {vs: 'VS'}
          case 'time':
            res = status.find(el => {return el.indexOf(':') > -1})
            if(res)
              return {time: res}
            else
              break
          case 'court':
            res = status.find(el => {return el.indexOf('场') > -1})
            if(res)
              return {court: res}
            else
              break
        }
      }
    },
    mixInfoHtml (arr) {
      let html = ''
      for(let i = 0; i < arr.length; i++) {
        let key = typeof arr[i] === 'object' ? Object.keys(arr[i])[0] : 'default'
        html += `<div class="info-${key}-style">${arr[i][key]}</div>`
      }
      return html
    },
    checkKey (arr, key) {
      return !arr ? false : arr.find(item => {
        return item.hasOwnProperty(key)
      })
    },
    createBetHtml (data) {
      let home_html = '', away_html = '',
          home_odds = this.checkData(data.mini_odds.team1.odds), 
          home_txt = this.checkData(data.mini_odds.team1.txt), 
          away_odds = this.checkData(data.mini_odds.team2.odds),
          away_txt = this.checkData(data.mini_odds.team2.txt)
      home_html = parseFloat(home_odds) < 0 ? 
                  `<span>${home_txt}</span><span class="red">${home_odds}</span>` :
                  `<span>${home_txt}</span><span class="blue">${home_odds}</span>`
      away_html = parseFloat(away_odds) < 0 ? 
                  `<span>${away_txt}</span><span class="red">${away_odds}</span>` :
                  `<span>${away_txt}</span><span class="blue">${away_odds}</span>`
      return {team1: home_html, team2: away_html}
    },
    handleShowListButton () {
      if (this.lists.length == 0) return
      let parentWidth = this.$refs.listBar.clientWidth
      let contentWidth = this.$refs.listContainer.clientWidth
      if (contentWidth <= parentWidth) {
        this.$refs.prevButton.classList.add('disable-button')
        this.$refs.nextButton.classList.add('disable-button')
      } else {
        if (this.$refs.listBar.scrollLeft <= 0) {
          this.$refs.prevButton.classList.add('disable-button')
        } else {
          this.$refs.prevButton.classList.remove('disable-button')
        }
        if (this.$refs.listBar.scrollLeft >= contentWidth - parentWidth) {
          this.$refs.nextButton.classList.add('disable-button')
        } else {
          this.$refs.nextButton.classList.remove('disable-button')
        }
      }
      this.hasHandleButton = true
    },
    handleListPrev () {
      let diff = this.$refs.listContainer.clientWidth - this.$refs.listBar.clientWidth
      let curPosition = this.$refs.listBar.scrollLeft
      if (curPosition > 0) {
        this.$nextTick(()=>{
          this.$refs.listBar.scrollLeft = this.$refs.listBar.scrollLeft - 200
          if (this.$refs.listBar.scrollLeft < diff) {
            this.$refs.nextButton.classList.remove('disable-button')
          }
          if (this.$refs.listBar.scrollLeft <= 0) {
            this.$refs.prevButton.classList.add('disable-button')
          }
        })
      }
    },
    handleListNext () {
      let diff = this.$refs.listContainer.clientWidth - this.$refs.listBar.clientWidth
      let curPosition = this.$refs.listBar.scrollLeft
      if (curPosition <= diff) {
        this.$nextTick(()=>{
          this.$refs.listBar.scrollLeft = this.$refs.listBar.scrollLeft + 200
          if (this.$refs.listBar.scrollLeft >= diff) {
            this.$refs.nextButton.classList.add('disable-button')
          }
          if (this.$refs.listBar.scrollLeft > 0) {
            this.$refs.prevButton.classList.remove('disable-button')
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/sportStyles';
.sport-detail-recommend-list {
  &.football-style {
    .recommend-header {
      background: $football-269633;
    }
    .recommend-container {
      .recommend-box {
        &.act-style {
          border: 1px solid $football-56895C!important;
          .league-section{
            color: $football-36722B!important;
          }
          .info-section {
            .team-name {color: $football-2F4031!important;}
          }
          .bet-section {background: $football-56895C!important;}
        }
      }
    }
  }
  &.baseketball-style {
    .recommend-header {
      background: $baseketball-EA8119;
    }
    .recommend-container {
      .recommend-box {
        &.act-style {
          border: 1px solid $baseketball-EAB988!important;
          .league-section{
            color: $baseketball-654B41!important;
          }
          .info-section {
            .team-name {color: $baseketball-36261F!important;}
          }
          .bet-section {background: $baseketball-EAB988!important;}
        }
      }
    }
  }
  .recommend-header {
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 15px 15px 0px 0px;
    img {
      width: 46px;
      margin-right: 13px;
    }
    h3 {
      color: #FFF;
      font: normal normal 600 21px/31px Poppins;
    }
  }
  .recommend-container {
    display: flex;
    position: relative;
    border: 1px solid #D7D7D7;
    justify-content: center;
    align-items: center;
    background: #FFF;
    .empty-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 222px;
      justify-content: center;
      align-items: center;
      img {
        width: 50px;
        margin-bottom: 16px;
      }
      h4 {
        color: #828282;
        font: normal normal normal 18px/22px Helvetica Neue;
        letter-spacing: 0.9px;
      }
    }
    button {display: none;}
    .prev-button {left: 5px;}
    .next-button {right: 5px;}
    .prev-button, .next-button {
      position: absolute;
      width: 20px;
      height: 20px;
      font: normal normal normal 14px/20px Poppins;
      color: #FFF;
      background: #D7D7D7;
      border-radius: 50%;
      border: none;
      top: 50%;
      transform: translateY(-60%);
      z-index: 5;
      &:focus, &:active {
        outline: none;
      }
      &:hover {
        opacity: 0.8;
      }
      &.disable-button{
        opacity: 0.2;
        cursor: default;
        &:hover {opacity: 0.2;}
      }
    }
    .recommend-list {
      cursor: default;
      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        width: 45px;
        height: calc(100% - 20px);
        top: 10px;
      }
      &::before {
        width: 35px;
        left: 0;
        background: transparent linear-gradient(-90deg, #FFFFFF00 0%, #FFFFFFEA 67%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
      }
      &::after {
        right: 0;
        background: transparent linear-gradient(90deg, #FFFFFF00 0%, #FFFFFFEA 67%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
      }
      .recommend-list-container {
        width: fit-content;
        .recommend-box {
          margin: 16px;
          border: 1px solid #D7D7D7;
          .league-section{
            width: 100%;
            height: 39px;
            color: #6598D8;
            font: normal normal bold 16px/39px Poppins;
            text-align: left;
            padding: 0 12px;
            background: #FFF;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-word;
          }
          .info-section {
            display: flex;
            height: 100px;
            align-items: center;
            padding: 0 10px;
            background-image: url(~@/assets/sport-match-bg.png);
            background-size: cover;
            background-position: top center;
            .team-name {
              max-width: 110px;
              flex-basis: 110px;
              font: normal normal 600 13px/20px Poppins;
              color: #1A55A5;
              word-break: break-word;
            }
            .info-detail {
              display: flex;
              flex-basis: 85px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin: 0 10px;
              & > div:nth-child(2) {
                &.info-vs-style{margin: 4px 0 2px;}
                &.info-time-style{margin: 2px 0 3px;}
              }
              .info-score-style {
                font: normal normal normal 16px/19px Helvetica;
                color: #EA821B;
              }
              .info-vs-style {
                font: normal normal normal 20px/23px Arial;
                color: #2F84ED;
              }
              .info-date-style, .info-time-style, .info-live-style {
                font: normal normal normal 14px/22px Arial;
                color: #5D5D5D;
              }
              .info-prepare-style {
                font: normal normal bold 14px/17px Helvetica;
                color: #EA821B;
              }
              .info-court-style {
                font: normal normal bold 14px/17px Helvetica;
                color: #2F84ED;
              }
            }
          }
          .bet-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 12px;
            background: #6598D8;
            .bet-box {
              display: flex;
              justify-content: space-around;
              align-items: center;
              width: 108px;
              height: 25px;
              color: #5D5D5D;
              background: #FFF;
              border-radius: 5px;
            }
            .red {color: #EB5757;}
            .blue {color: #2F84ED;}
            .bet-text {
              min-width: 3em;
              color: #FFF;
              font: normal normal normal 12px/18px Poppins;
              margin: 0 6px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .sport-detail-recommend-list {
    .recommend-header {
      height: 34px;
      border-radius: 15px 15px 0px 0px;
      margin: 0 15px;
      img {
        width: 24px;
        margin-right: 9px;
      }
      h3 {
        font: normal normal 600 14px/34px Poppins;
      }
    }
    .recommend-container {
      border: 1px solid #FFF;
      background: #FFF;
      .empty-content {
        width: 100%;
        height: 150px;
        img {
          width: 45px;
          margin-bottom: 10px;
        }
        h4 {
          color: #828282;
          font: normal normal normal 14px/22px Helvetica Neue;
          letter-spacing: 1px;
        }
      }
      button {display: inline-block;}
      .recommend-list {
        overflow-x: scroll;
        padding: 10px 0;
        &::-webkit-scrollbar {display: none;}
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        scroll-behavior: smooth;
        .recommend-list-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 30px;
          .recommend-box {
            width: 196px;
            min-width: 196px;
            margin: 0 8px 0 0;
            border: 1px solid #D7D7D7;
            &:last-child {
              margin-right: 0;
            }
            .league-section{
              width: 100%;
              height: 27px;
              font: normal normal bold 12px/27px Poppins;
              padding: 0 5px;
            }
            .info-section {
              height: 70px;
              padding: 0 8px;
              .team-name {
                max-width: 72px;
                flex-basis: 72px;
                font: normal normal 600 12px/18px Poppins;
              }
              .info-detail {
                flex-basis: 52px;
                margin: 0 8px;
                & > div:nth-child(2) {
                  &.info-vs-style{margin: 4px 0 2px;}
                  &.info-time-style{margin: 2px 0 3px;}
                }
                .info-score-style {
                  font: normal normal normal 14px/18px Helvetica;
                  color: #EA821B;
                }
                .info-vs-style {
                  font: normal normal normal 19px/22px Arial;
                  color: #EB5757;
                }
                .info-date-style, .info-time-style, .info-live-style {
                  font: normal normal normal 12px/14px Arial;
                  color: #5D5D5D;
                }
                .info-prepare-style {
                  font: normal normal bold 12px/14px Helvetica;
                  color: #EA821B;
                }
                .info-court-style {
                  font: normal normal bold 12px/14px Helvetica;
                  color: #2F84ED;
                }
              }
            }
            .bet-section {
              padding: 6px;
              .bet-box {
                padding: 3px;
                justify-content: space-around;
                width: 75px;
                height: 18px;
                color: #5D5D5D;
                background: #FFF;
                border-radius: 5px;
              }
              .bet-text {
                font: normal normal normal 12px/15px Poppins;
                margin: 0 4px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .sport-detail-recommend-list {
    .recommend-header {
      height: 34px;
      border-radius: 15px 15px 0px 0px;
      margin: 0 15px;
      img {
        width: 24px;
        margin-right: 9px;
      }
      h3 {
        font: normal normal 600 14px/34px Poppins;
      }
    }
    .recommend-container {
      border: 1px solid #FFF;
      background: #FFF;
      .empty-content {
        width: 100%;
        height: 150px;
        img {
          width: 45px;
          margin-bottom: 10px;
        }
        h4 {
          color: #828282;
          font: normal normal normal 14px/22px Helvetica Neue;
          letter-spacing: 1px;
        }
      }
      button {display: inline-block;}
      .recommend-list {
        overflow-x: scroll;
        padding: 10px 0;
        &::-webkit-scrollbar {display: none;}
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        scroll-behavior: smooth;
        .recommend-list-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 30px;
          .recommend-box {
            width: 196px;
            min-width: 196px;
            margin: 0 8px 0 0;
            border: 1px solid #D7D7D7;
            &:last-child {
              margin-right: 0;
            }
            .league-section{
              width: 100%;
              height: 27px;
              font: normal normal bold 12px/27px Poppins;
              padding: 0 5px;
            }
            .info-section {
              height: 70px;
              padding: 0 8px;
              .team-name {
                max-width: 72px;
                flex-basis: 72px;
                font: normal normal 600 12px/18px Poppins;
              }
              .info-detail {
                flex-basis: 52px;
                margin: 0 8px;
                & > div:nth-child(2) {
                  &.info-vs-style{margin: 4px 0 2px;}
                  &.info-time-style{margin: 2px 0 3px;}
                }
                .info-score-style {
                  font: normal normal normal 14px/18px Helvetica;
                  color: #EA821B;
                }
                .info-vs-style {
                  font: normal normal normal 19px/22px Arial;
                  color: #EB5757;
                }
                .info-date-style, .info-time-style, .info-live-style {
                  font: normal normal normal 12px/14px Arial;
                  color: #5D5D5D;
                }
                .info-prepare-style {
                  font: normal normal bold 12px/14px Helvetica;
                  color: #EA821B;
                }
                .info-court-style {
                  font: normal normal bold 12px/14px Helvetica;
                  color: #2F84ED;
                }
              }
            }
            .bet-section {
              padding: 6px;
              .bet-box {
                padding: 3px;
                justify-content: space-around;
                width: 75px;
                height: 18px;
                color: #5D5D5D;
                background: #FFF;
                border-radius: 5px;
              }
              .bet-text {
                font: normal normal normal 12px/15px Poppins;
                margin: 0 4px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .sport-detail-recommend-list {
    margin-bottom: 20px;
    .recommend-header {
      display: flex;
      height: 50px;
      justify-content: center;
      align-items: center;
      border-radius: 15px 15px 0px 0px;
      img {
        width: 46px;
        margin-right: 13px;
      }
      h3 {
        color: #FFF;
        font: normal normal 600 21px/31px Poppins;
      }
    }
    .recommend-container {
      width: 100%;
      border: 1px solid hsl(0, 0%, 84%);
      background: #FFF;
      .empty-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 222px;
        justify-content: center;
        align-items: center;
        img {
          width: 50px;
          margin-bottom: 16px;
        }
        h4 {
          color: #828282;
          font: normal normal normal 18px/22px Helvetica Neue;
          letter-spacing: 0.9px;
        }
      }
      button {display: inline-block;}
      .prev-button, .next-button {
        width: 30px;
        height: 30px;
      }
      .recommend-list {
        overflow-x: scroll;
        padding: 16px 0;
        &::-webkit-scrollbar {display: none;}
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        scroll-behavior: smooth;
        &::before {
          width: 50px;
        }
        .recommend-list-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 40px;
          .recommend-box {
            display: inline-block;
            width: 240px;
            margin: 0 16px 0 0;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .sport-detail-recommend-list {
    .recommend-header {
      height: 50px;
      border-radius: 15px 15px 0px 0px;
      img {
        width: 46px;
        margin-right: 13px;
      }
      h3 {
        font: normal normal 600 21px/31px Poppins;
      }
    }
    .recommend-container {
      .empty-content {
        img {
          width: 50px;
          margin-bottom: 16px;
        }
        h4 {
          font: normal normal normal 18px/22px Helvetica Neue;
          letter-spacing: 0.9px;
        }
      }
      .recommend-list {
        width: 100%;
        padding: 0 16px;
        &::before, &::after {display: none;}
        .recommend-list-container {
          .recommend-box {
            margin: 16px 0;
            .league-section{
              height: 39px;
              font: normal normal bold 16px/39px Poppins;
              padding: 0 12px;
            }
            .info-section {
              height: 100px;
              padding: 0 10px;
              .team-name {
                max-width: 110px;
                flex-basis: 110px;
                font: normal normal 600 13px/20px Poppins;
              }
              .info-detail {
                flex-basis: 85px;
                margin: 0 10px;
                & > div:nth-child(2) {
                  &.info-vs-style{margin: 4px 0 2px;}
                  &.info-time-style{margin: 2px 0 3px;}
                }
                .info-score-style {
                  font: normal normal normal 16px/19px Helvetica;
                }
                .info-vs-style {
                  font: normal normal normal 20px/23px Arial;
                }
                .info-date-style, .info-time-style, .info-live-style {
                  font: normal normal normal 14px/22px Arial;
                }
                .info-prepare-style {
                  font: normal normal bold 14px/17px Helvetica;
                }
                .info-court-style {
                  font: normal normal bold 14px/17px Helvetica;
                }
              }
            }
            .bet-section {
              padding: 10px;
              .bet-box {
                width: 86px;
                height: 25px;
              }
              .bet-text {
                margin: 0 4px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .sport-detail-recommend-list {
    .recommend-header {
      h3 {
        font: normal normal 600 21px/31px Poppins;
      }
    }
    .recommend-container {
      .empty-content {
        height: 222px;
        h4 {
          font: normal normal normal 18px/22px Helvetica Neue;
        }
      }
      .recommend-list {
        width: 100%;
        padding: 0 16px;
        &::before, &::after {display: none;}
        .recommend-list-container {
          .recommend-box {
            margin: 16px 0;
            .league-section{
              height: 39px;
              font: normal normal bold 16px/39px Poppins;
            }
            .info-section {
              height: 100px;
              padding: 0 10px;
              .team-name {
                max-width: 110px;
                flex-basis: 110px;
                font: normal normal 600 13px/20px Poppins;
              }
              .info-detail {
                flex-basis: 85px;
                margin: 0 10px;
                & > div:nth-child(2) {
                  &.info-vs-style{margin: 4px 0 2px;}
                  &.info-time-style{margin: 2px 0 3px;}
                }
                .info-score-style {
                  font: normal normal normal 16px/19px Helvetica;
                }
                .info-vs-style {
                  font: normal normal normal 20px/23px Arial;
                }
                .info-date-style, .info-time-style, .info-live-style {
                  font: normal normal normal 14px/22px Arial;
                }
                .info-prepare-style {
                  font: normal normal bold 14px/17px Helvetica;
                }
                .info-court-style {
                  font: normal normal bold 14px/17px Helvetica;
                }
              }
            }
            .bet-section {
              padding: 10px 12px;
              .bet-box {
                width: 108px;
                height: 25px;
              }
              .bet-text {
                font: normal normal normal 12px/18px Poppins;
                margin: 0 6px;
              }
            }
          }
        }
      }
    }
  }
}
</style>