<template>
  <div class="sport-detail">
    <div class="sport-detail-header">
      <h3>体育赛事</h3>
    </div>
    <div class="sport-detail-main">
      <ul class="sports-tabs">
        <li
          v-for="(item, key) in getTabs"
          :name="item.key"
          :key="item + key"
          class="sport-tab"
          :class="[{'tab-act': item.key === tab}, `tab-${item.key}`]"
          @click="changeTab(item.key)">
          {{item.context}}
        </li>
      </ul>
      <div class="detail-main-section">
        <div class="detail-left-section">
          <Channel
            :show="showChannel"
            :url="liveUrl" />
          <div class="detail-table-section">
            <TableHeader 
              :sport="tab"
              :tab_info="cur_setting"
              :checkbox_status="checkbox_status"
              @handleUnfoldPanels="handleUnfoldPanels"
              @handleDataQuery="handleDataQuery"
              @handleReload="handleReload"
              @handleTableReverse="handleTableReverse"
              @handleMultiCheckboxStatus="handleMultiCheckboxStatus" />
            <TableBody
              :sport="tab"
              :filtered="filtered"
              :isLoading="loading"
              :results="sortableData"
              :unfold_all="unfold"
              @handleUnfoldPanels="handleUnfoldPanels" />
          </div>
        </div>
        <div class="detail-right-section">
          <div class="recommends-section">
            <List
              :sport="tab"
              :lists="recommend_list" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sportDetailsInfo } from '@/constants/sportsOptions'
import { getDetailFootballInfo, getDetailBaseketballInfo, getLeagues, getRecommendSportList, getSportLiveStream } from '@/api/client/sports'
import { getMonth, getDate } from '@/utils/time'
import resStatus from '@/constants/resStatus'
import Channel from './Channel'
import TableHeader from './Table/header'
import TableBody from './Table/body'
import List from './List'

export default {
  components: {
    Channel,
    TableHeader,
    TableBody,
    List
  },
  data () {
    return {
      tab: 'football',
      result: [],
      cur_setting: {},
      query: {
        type: 'dilcds',
        locale_odds: 'malay',
        category: 'early',
        league: '',
        belong_date: '',
        sort: 'normal'
      },
      recommend_list: [],
      loading: false,
      filtered: 0, // 0:'暂无数据', 1:'筛选后无符合数据',
      recommend_interval: null,
      checkbox_status: 0, // 0: close, 1:open
      unfold: false,
      liveUrl: ''
    }
  },
  created () {
    this.init()
    this.recommend_interval = setInterval(() => {
      this.handleRecommendList()
    }, 5000)
  },
  destroyed () {
    clearInterval(this.recommend_interval)
  },
  computed: {
    getTabs () {
      return sportDetailsInfo.map(item => {
        return {
          key: item.key,
          context: item.context
        }
      })
    },
    setCurSportDetail: {
      get () {
        return this.cur_setting
      },
      set (val) {
        this.cur_setting = sportDetailsInfo.find(item => item.key === val)
      }
    },
    sortableData: {
      get () {
        return this.result
      },
      set (data) {
        data.map(item => {
          let plays = item.plays
          let obj = plays.map (play => {
            let play_at_month = getMonth(play.play_at)
            let play_at_date = getDate(play.play_at)
            let play_at_time = play.play_at.split(' ')[1]
            let play_at_html = `${play_at_month}/${play_at_date}`
                             + `<div class="play-at-time">${play_at_time}</div>`
            play.play_at_html = play_at_html
            return play
          })
          item.plays = obj
          return item
        })
        this.result = data
      }
    },
    showChannel () {
      return this.tab === 'football' && this.liveUrl !== ''
    }
  },
  methods: {
    init () {
      this.setCurSportDetail = this.tab
      this.handleLiveStream()
      this.resetQuery()
      this.handleRecommendList()
      // this.handleLeagues()
    },
    resetQuery () {
      this.loading = true
      this.filtered = 0
      this.checkbox_status = 0
      this.unfold = false
      this.sortableData = []
      this.recommend_list = []
      this.query.type = this.cur_setting.type[0].value
      this.query.locale_odds = 'malay'
      this.query.league = ''
      this.query.sort = 'normal'
    },
    changeTab (val) {
      this.tab = val
      this.init()
    },
    handleLeagues () {
      this.query.league = ''
      let params = {
        sport: this.tab,
        type: this.query.type,
        category: this.query.category,
        belong_date: this.query.belong_date
      }
      getLeagues(params).then(res => {
        if (res.status === resStatus.OK) {
          this.cur_setting['league'] = res.data
        }
      }).catch(err => {
        console.log('Error:' + err)
      })
    },
    handleRecommendList () {
      let params = {
        sport: this.tab,
        locale_odds: this.query.locale_odds
      }
      getRecommendSportList(params).then(res => {
        if (res.status === resStatus.OK) {
          this.recommend_list = res.data
        }
      }).catch(err => {
        console.log('Error:' + err)
      })
    },
    handleDataQuery (obj) {
      this.loading = true
      this.filtered = obj.hasOwnProperty('filtered') ? Number(obj.filtered) : 1
      for (let key in this.query) {
        if (obj.hasOwnProperty(key)) {
          this.query[key] = obj[key]
        }
      }
      if (obj.hasOwnProperty('belong_date')) {
        this.handleLeagues()
      }
      if (obj.hasOwnProperty('locale_odds')) {
        this.handleRecommendList()
      }
      this.handleTableData()
    },
    handleTableData () {
      const func = this.tab === 'football' ? getDetailFootballInfo : getDetailBaseketballInfo
      func(this.query).then(res => {
        this.loading = false
        if (res.status === resStatus.OK) {
          this.sortableData = res.data
        }
      }).catch(err => {
        this.loading = false
        console.log('Error:' + err)
      })
    },
    handleReload () {
      this.loading = true
      this.handleTableData()
    },
    handleTableReverse () {
      switch (this.query.sort) {
        case 'normal':
          this.result = this.normalReverse()
          break;
        case 'date':
          this.result = this.dateReverse()
          break;
      }
    },
    normalReverse () {
      return this.result.reverse()
    },
    dateReverse () {
      return this.result.map(item => {
        let arr = item.plays
        item.plays = arr.reverse()
        return item
      }).reverse()
    },
    handleUnfoldPanels (boo) {
      this.unfold = boo
    },
    handleMultiCheckboxStatus (int) {
      this.checkbox_status = int
    },
    handleLiveStream () {
      getSportLiveStream().then(res => {
        if (res.status === resStatus.OK) {
          if (res.data !== '') {
            console.log(res.data)
            this.liveUrl = res.data
          }
        }
      }).catch(err => {
        console.log('Error:' + err)
      })
    }
    // handleCloseCheckbox () {
    //   this.checkbox_status = 0
    // }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/sportStyles';
.sport-detail {
  width: 100%;
  margin: 20px 0;
  .sport-detail-header {
    height: 200px;
    background-image: url('~@/assets/sport-detail-top.png');
    background-position: center;
    background-size: cover;
    margin-bottom: 20px;
    padding: 128px 47px 22px;
    h3 {
      font: normal normal 600 36px/55px Poppins;
      color: #FFF;
      text-align: left;
      text-shadow: 0px 3px 3px #00000029;
    }
  }
  .sport-detail-main {
    padding: 35px 35px 40px;
    background: #FFF;
    .sports-tabs {
      text-align: left;
      margin-bottom: 20px;
      li {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 50px;
        color: #FFFFFF;
        font: normal normal 600 21px/50px Poppins;
        text-align: center;
        margin: 0 0 0 12px;
        background: #D7D7D7 0% 0% no-repeat padding-box;
        border: 1px solid #D7D7D7;
        border-radius: 50px;
        cursor: pointer;
        &.tab-act {
          &.tab-football {
            background: transparent linear-gradient(180deg, $football-479550 0%, $football-11711A 100%) 0% 0% no-repeat padding-box;
            border: 1px solid $football-15741E;
            &::before {background-image: url('~@/assets/football-act.png');}
          }
          &.tab-baseketball {
            background: transparent linear-gradient(180deg, $baseketball-EF9842 0%, $baseketball-E87200 100%) 0% 0% no-repeat padding-box;
            border: 1px solid $baseketball-D36F0D;
            &::before {background-image: url('~@/assets/baseketball-act.png');}
          }
        }
        &.tab-football::before, &.tab-baseketball::before {
          content: '';
          display: inline-block;
          width: 40px;
          height: 40px;
          background-position: center;
          background-size: cover;
          margin-right: 7px;
        }
        &.tab-football::before {background-image: url('~@/assets/football.png');}
        &.tab-baseketball::before {background-image: url('~@/assets/baseketball.png');}
      }
    }
    .detail-main-section {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .detail-left-section {
        width: calc(100vw * 0.6625);
        margin-right: 20px;
        .detail-table-section {
          width: 100%;
          border: 1px solid #D7D7D7;
          padding: 20px 16px;
          min-height: 406px;
        }
      }
      .detail-right-section {
        width: calc(100vw * 0.2625);
      }
    }
  }
}
@media (max-width: 577.98px) {
  .sport-detail {
    margin: 0;
    .sport-detail-header {
      height: 67px;
      margin-bottom: 0;
      background: #56CCF2;
      background-image: none;
      padding: 0;
      h3 {
        font: normal normal bold 21px/67px Arial;
        color: #FFF;
        text-shadow: none;
        text-align: center;
      }
    }
    .sport-detail-main {
      padding: 20px 0 15px;
      background-color: transparent;
      .sports-tabs {
        text-align: center;
        margin-bottom: 10px;
        padding: 0 15px;
        li {
          width: 130px;
          height: 36px;
          font: normal normal 600 15px/36px Poppins;
          &.tab-football::before, &.tab-baseketball::before {
            width: 30px;
            height: 30px;
            margin-right: 4px;
          }
        }
      }
      .detail-main-section {
        flex-direction: column-reverse;
        .detail-left-section {
          width: 100%;
          margin-top: 10px;
          .detail-table-section {
            border: none;
            padding: 15px;
            min-height: 220px;
            background-color: #FFF;
          }
        }
        .detail-right-section {
          width: 100%;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .sport-detail {
    margin: 0;
    .sport-detail-header {
      height: 67px;
      margin-bottom: 0;
      background: #56CCF2;
      background-image: none;
      padding: 0;
      h3 {
        font: normal normal bold 21px/67px Arial;
        color: #FFF;
        text-shadow: none;
        text-align: center;
      }
    }
    .sport-detail-main {
      padding: 20px 0 15px;
      background-color: transparent;
      .sports-tabs {
        text-align: center;
        margin-bottom: 10px;
        padding: 0 15px;
        li {
          width: 130px;
          height: 36px;
          font: normal normal 600 15px/36px Poppins;
          &.tab-football::before, &.tab-baseketball::before {
            width: 30px;
            height: 30px;
            margin-right: 4px;
          }
        }
      }
      .detail-main-section {
        flex-direction: column-reverse;
        .detail-left-section {
          width: 100%;
          margin-top: 10px;
          .detail-table-section {
            border: none;
            padding: 15px;
            min-height: 220px;
            background-color: #FFF;
          }
        }
        .detail-right-section {
          width: 100%;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .sport-detail {
    margin: 0;
    .sport-detail-header {
      height: 200px;
      margin-bottom: 15px;
      padding: 128px 40px 20px;
    }
    .sport-detail-main {
      padding: 30px 25px 35px;
      .sports-tabs {
        margin-bottom: 20px;
        li {
          width: 170px;
          font: normal normal 600 20px/50px Poppins;
        }
      }
      .detail-main-section {
        flex-direction: column-reverse;
        .detail-left-section {
          width: 100%;
        }
        .detail-right-section {
          width: 100%;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .sport-detail {
    .sport-detail-header {
      padding: 128px 50px 20px;
      h3 {
        font: normal normal 600 32px/48px Poppins;
      }
    }
    .sport-detail-main {
      padding: 30px 30px 40px;
      .sports-tabs {
        li {
          width: 160px;
          &.tab-football::before, &.tab-baseketball::before {
            width: 36px;
            height: 36px;
          }
        }
      }
      .detail-main-section {
        .detail-left-section {
          .detail-table-section {
            padding: 20px 16px;
            min-height: 406px;
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .sport-detail {
    max-width: 1200px;
    .sport-detail-main {
      width: 100%;
      margin: 0 auto;
      border: 1px solid #D7D7D7;
      box-shadow: 0px 12px 10px #0000000D;
      border-radius: 5px;
      .detail-main-section {
        .detail-left-section {
          width: 795px;
        }
        .detail-right-section {
          width: 315px;
        }
      }
    }
  }
}
</style>